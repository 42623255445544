<template>
  <div class="main" v-loading="loading">
    <div class="top">
      <div class="title">消息通知</div>
      <div class="right">
        <el-button type="primary" icon="el-icon-refresh" @click="getData">
          刷新
        </el-button>
        剩余未读{{ unreadNum }}条
        <el-button size="small" @click="handleAllHaveRead('')">
          全部设为已读
        </el-button>
      </div>
    </div>
    <Page :showTitle="false">
      <div class="table-wrap">
        <el-table border :data="tableData">
          <el-table-column
            header-align="center"
            align="center"
            show-overflow-tooltip
            prop="messageContent"
            label="通知内容"
          />
          <el-table-column
            header-align="center"
            align="center"
            show-overflow-tooltip
            prop="messageType"
            label="消息类型"
          />
          <el-table-column
            header-align="center"
            align="center"
            show-overflow-tooltip
            prop="readable"
            label="状态"
          >
            <template slot-scope="{ row }">
              {{ row.readable == 1 ? '已读' : '未读' }}
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="createTime"
            show-overflow-tooltip
            label="通知时间"
          ></el-table-column>

          <el-table-column
            header-align="center"
            show-overflow-tooltip
            label="操作"
            width="150"
          >
            <template slot-scope="{ row }">
              <el-button
                size="medium"
                type="text"
                v-auth="'detail'"
                @click="toDetail(row)"
              >
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <Pagination
        :total="total"
        :page-size.sync="params.pageSize"
        :page.sync="params.pageNo"
        @change="getData"
      />
    </Page>
  </div>
</template>

<script>
import watchParamsGetData from '../../mixins/watchParamsGetData'
export default {
  components: {},
  data() {
    return {
      confirmVisible: false,
      unreadNum: 0,
      msgType: {
        CC_NOTIFY: '审批抄送提醒'
      }
    }
  },
  mixins: [watchParamsGetData],
  methods: {
    async getData() {
      try {
        this.loading = true
        let params = { ...this.params }
        this.unreadNum = await this.$api.workOrder.getUnreadNum()
        this.$utils.refreshData(this.unreadNum)
        const { list, total } = await this.$api.workOrder.getNotifyList(params)
        this.tableData = (list || []).map(item => {
          // let msgTypeStr = this.msgType[item.messageType]
          //   ? this.msgType[item.messageType]
          //   : ''

          return { ...item }
        })
        this.total = total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async toDetail(row) {
      try {
        if (row.readable === 0) {
          this.handleAllHaveRead(row.id)
        }
        let res = await this.$api.apply.applyResult({
          processCode: row.uniqueValue
        })
        if (res) {
          this.$router.push({
            path: '/myAudit/detail',
            query: {
              processCode: res.orderCode,
              processInstanceId: res.processId,
              type: res.type,
              from: 'msgNotice'
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async handleAllHaveRead(id) {
      try {
        await this.$api.workOrder.readData({ id })
        this.getData()
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="less">
.main {
  background: #fff;
}
.top {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  border-bottom: 1px solid #f6f6f6;
}
</style>
